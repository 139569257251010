import React, { Component } from 'react';
import { Carousel } from 'react-bootstrap';
import PropTypes from 'prop-types';

class CarouselComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedImageIndex: 0,
            carouselItems: [],
            hasValidCarouselItems: false,
            showCarousel: props.showCarousel,
        };
    }

    componentDidMount() {
        this.loadCarouselConfig();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.showCarousel !== this.props.showCarousel) {
            this.setState({ showCarousel: this.props.showCarousel });
        }
    }


    loadCarouselConfig() {
        fetch(`${process.env.PUBLIC_URL}/docs/carousel/carousel-config.json`)
            .then(response => response.json())
            .then(data => {
                const today = new Date().toISOString().split('T')[0]; // Current date in YYYY-MM-DD format

                // Filter items based on the current date
                const filteredItems = data.Carousel.filter(item => {
                    const currentDate = new Date(today);
                    const start = new Date(item.startDate);
                    const end = new Date(item.endDate);
                    return currentDate >= start && currentDate <= end;
                });

                // Check if there are valid items
                if (filteredItems.length > 0) {
                    this.setState({
                        carouselItems: filteredItems,
                        hasValidCarouselItems: true
                    });
                } else {
                    this.setState({
                        hasValidCarouselItems: false
                    });
                }
            })
            .catch(error => {
                console.error('Error loading carousel config:', error);
                this.setState({
                    hasValidCarouselItems: false
                });
            });
    }

    handleCarouselClose = (event) => {
        if (event.target === event.currentTarget) {
            this.setState({ showCarousel: false });
            this.props.onClose();  // Notify parent to close carousel
        }
    }

    handleImageClick = (url) => {
        //window.location.href = url; // opens the link in the same window
        window.open(url, '_blank'); // '_blank' opens the link in a new tab
        this.props.onClose();  // Notify parent to close carousel
    }

    render() {
        const { selectedImageIndex, carouselItems, hasValidCarouselItems, showCarousel } = this.state;

        if (!showCarousel) {
            return null;
        }

        return (
            showCarousel && hasValidCarouselItems && (
                <div className="carousel-overlay" onClick={this.handleCarouselClose}>
                    <div className="carousel-container">
                        {/* Close Button */}
                        <button className="carousel-close-button" onClick={this.handleCarouselClose}>
                            &times;
                        </button>

                        <Carousel
                            activeIndex={selectedImageIndex}
                            onSelect={(selectedIndex) => this.setState({ selectedImageIndex: selectedIndex })}
                        >
                            {carouselItems.map((item, index) => (
                                <Carousel.Item key={index}>
                                    <img
                                        className="d-block w-100"
                                        src={`${process.env.PUBLIC_URL}/docs/carousel/${item.imageName}`}
                                        alt={`carousel-item-${index}`}
                                        onClick={(e) => {
                                            e.stopPropagation(); // Prevent click event from closing the carousel
                                            this.handleImageClick(item.url); // Redirect to the URL
                                        }}
                                    />
                                </Carousel.Item>
                            ))}
                        </Carousel>
                    </div>
                </div>
            )
        );
    }
}

CarouselComponent.propTypes = {
    showCarousel: PropTypes.bool,
    onClose: PropTypes.func
};

CarouselComponent.defaultProps = {
    showCarousel: false,
    onClose: () => { }
};

export default CarouselComponent;
