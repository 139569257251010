import React, { Component } from 'react';
import { Container, Row, Col, Table } from 'react-bootstrap';
import { MapContainer, Marker, TileLayer, Tooltip, AttributionControl } from 'react-leaflet';
import { POICommand } from '../commands/POICommand';
import { strings } from '../../resources/strings';
import { externalTicketOfficeIcon, ticketOfficeIcon } from '../leaflet/StopIcons';

export class TicketOffice extends Component {
    constructor(props) {
        super(props);

        this.state = {
            commands: {
                poi: new POICommand()
            },
            nearTO: []
        }
    }

    componentDidMount() {
        this.getNearPOI();
    }

    getNearPOI() {
        this.state.commands.poi.getNearPOI(41.4403427596919, -8.30417769847734, 10000000, (r) => this.getNearPOISuccessCallback(r))
    }

    getNearPOISuccessCallback(result) {
        const { nearTO } = this.state;

        this.setState({
            nearTO: nearTO.concat(result.filter(r => nearTO.find(s => s.id === r.id) === undefined))
        });
    }

    render() {
        return (
            <Container fluid className="mb-5" >
                <Row>
                    <Col lg={12}>
                        {/*<div className="mt-5">*/}
                        {/*    <b>{strings.contactHeader}</b><br />*/}
                        {/*    <b>Praça António Raposo Tavares<br />*/}
                        {/*        7800 - 426 Beja</b><br />*/}
                        {/*    <b>{strings.email}:</b> geral@trimbal.pt<br />*/}
                        {/*    <b>{strings.phone}:</b> 284 401 227<br /><br />*/}
                        {/*    <p>{strings.contactWorkingDays}<br />*/}
                        {/*        {strings.contactSaturdays}<br />*/}
                        {/*        {strings.contactSundays}</p>*/}
                        {/*</div>*/}
                        <div className="mt-5">
                            <Table striped responsive className="ticket-office-table">
                                {/*<thead>*/}
                                {/*    <tr>*/}
                                {/*        <th>{strings.town}</th>*/}
                                {/*        <th>{strings.address}</th>*/}
                                {/*        <th>{strings.schedule}</th>*/}
                                {/*    </tr>*/}
                                {/*</thead>*/}
                                <tbody>
                                    <tr>
                                        <td><b>Guimarães</b>Bilheteira Transdev</td>
                                        <td>Alameda Dr. Mariano Felgueiras - GuimarãesShopping</td>
                                        <td>
                                            {strings.workingDays}: 07h00 - 20h00<br />
                                            {strings.saturdaysHolidays}: 08h00 - 11h30 / 12h30 - 17h00<br />
                                            {strings.sundaysMondaysIfHolidays}: 10h30 - 13h30 / 14h30 - 19h30
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><b>Famalicão</b>Bilheteira Transdev</td>
                                        <td>Estação Rodoviária de Famalicão - Alameda Dr. Francisco Sá Carneiro</td>
                                        <td>
                                            {strings.workingDays}: 08h30 - 19h00<br />
                                            {strings.saturdaysSundaysHolidays}: 09h00 - 13h00 / 14h00 - 18h00
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><b>Fafe</b>Bilheteira Transdev</td>
                                        <td>Luis Costa Lemos - Avenida do Brasil nº 5</td>
                                        <td>
                                            {strings.daily}: 06h30 - 19h00
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><b>Póvoa de Lanhoso</b>Agente Transdev</td>
                                        <td>Quiosque José Alberto Conçalves dos Santos - Rua Comandante Luís Pinto da Silva nº 76</td>
                                        <td>
                                            {strings.workingDays}: 08h30 - 19h00<br />
                                            {strings.saturdays}: 08h30 - 13h00
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><b>Cabeceiras de Basto</b>Bilheteira Transdev</td>
                                        <td>Alameda Camilo Castelo Branco nº 39</td>
                                        <td>
                                            {strings.workingDays}: 08h15 - 18h15
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><b>Mondim de Basto</b>Bilheteira Transdev</td>
                                        <td>Av. dos Bombeiros</td>
                                        <td>
                                            {strings.workingDays}: 08h00 - 12h00 / 13h00 - 17h30
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><b>Vizela</b>Agente Transdev</td>
                                        <td>Café da Estação de Vizela - Largo 5 de Agosto</td>
                                        <td>
                                            {strings.mondayToSaturday}: 07h00 - 18h00
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><b>Vieira do Minho</b>Balcão Cidadão</td>
                                        <td>Câmara Municipal de Vieira do Minho - Praça Dr. Guilherme de Abreu nº 85</td>
                                        <td>
                                            {strings.workingDays}: 09h00 - 17h30
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><b>Santo Tirso</b>Bilheteira Pacense</td>
                                        <td>Central de Camionagem - Rua Infante Dom Henrique</td>
                                        <td>
                                            {strings.workingDays}: 08h30 - 17h30
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><b>Braga</b>Bilheteira Transdev</td>
                                        <td>Avenida General Norton de Matos nº 110</td>
                                        <td>
                                            {strings.daily}: 06h00 - 20h30
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><b>Felgueiras</b>Agente Landim</td>
                                        <td>Av. Dr. Leonardo Coimbra, Ed. Avenida, sala 1</td>
                                        <td>
                                            {strings.workingDays}: 09h00 - 12h00 / 14h00 - 18h30
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><b>Joane</b>Agente Transdev</td>
                                        <td>Quiosque Central de Joane - Largo da República 41</td>
                                        <td>
                                            {strings.workingDays}: 07h00 - 20h00<br />
                                            {strings.saturdays}: 07h00 - 19h00<br />
                                            {strings.sundays}: 07h00 - 13h00<br />
                                        </td>
                                    </tr>
                                    <tr>
                                      <td><b>Agentes Pagaqui</b></td>
                                      <td>Consulte <a target="_blank" href="/docs/Ave Mobilidade - Paga aqui INFO_2311.pdf"><b>aqui</b></a> o agente Pagaqui mais próximo de si</td>
                                      <td></td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>

                        <MapContainer className="map-container-ticket-office" center={[41.4403427596919, -8.30417769847734]} zoom={10} scrollWheelZoom={true} attributionControl={false}>
                            <TileLayer
                                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
                                url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png"
                            />
                            <AttributionControl position="bottomright" prefix={false} />
                            {this.state.nearTO.map((ticketOffice) =>
                                <Marker
                                    key={`to-${ticketOffice.code}`}
                                    position={[ticketOffice.coordX, ticketOffice.coordY]}
                                    icon={ticketOffice.type === 11 ? externalTicketOfficeIcon : ticketOfficeIcon}>

                                    <Tooltip>
                                        <strong>{ticketOffice.name}</strong>
                                    </Tooltip>
                                </Marker>
                            )}
                        </MapContainer>
                    </Col>
                </Row>
            </Container>
        );
    }
}
