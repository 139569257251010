import React, { Component } from 'react';
import { strings } from '../resources/strings';
import { MessageCommand } from './commands/MessageCommand';
import { Row, Col, ListGroup, ListGroupItem } from 'react-bootstrap';
import { PropTypes } from 'prop-types';
import { LinkContainer } from 'react-router-bootstrap';
import Carousel from './Carousel';

export class LandingPage extends Component {
    static displayName = LandingPage.name;

    static contextTypes = {
        updateLanguage: PropTypes.func
    };

    constructor(props) {
        super(props);

        this.state = {
            messages: [],
            isLoadingMessages: true,
            commands: {
                messages: new MessageCommand()
            },
            showCarousel: false
        };
    }

    componentDidMount() {
        this.getMessages();
        this.carouselTimeout = setTimeout(() => {
            this.setState({ showCarousel: true });
        }, 500);
    }


    componentWillUnmount() {
        if (this.carouselTimeout) {
            clearTimeout(this.carouselTimeout);
        }
    }

    getMessages() {
        this.setState({ isLoadingMessages: true });

        const { commands } = this.state;
        commands.messages.getMessages((r) => this.getMessagesSuccessCallback(r));
    }

    getMessagesSuccessCallback(result) {
        this.setState({
            messages: result,
            isLoadingMessages: false
        });
    }

    handleCarouselClose = () => {
        this.setState({ showCarousel: false });
    }

    renderMessages() {
        const { messages } = this.state;

        if (messages.length !== 0) {
            return (
                <ListGroup>
                    {
                        messages.map((message, index) =>
                            index < 2 ?
                                message.type.toLowerCase() === 'aviso' || message.type.toLowerCase() === 'warning' ?
                                    <ListGroupItem className="landing-page-warnings-list-item" key={`message-creation-date${index}`}>
                                        <Row>
                                            <Col sm={12} className="d-flex align-items-center">
                                                <b>{message.creationDateToShow}</b>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={12} className="d-flex align-items-center">
                                                <div dangerouslySetInnerHTML={{ __html: message.text }} />
                                            </Col>
                                        </Row>
                                    </ListGroupItem>
                                    : message.type.toLowerCase() === 'imagem' || message.type.toLowerCase() === 'image' ?
                                        <ListGroupItem className="landing-page-warnings-list-item" key={`message-creation-date${index}`}>
                                            <Row>
                                                <Col sm={12} className="d-flex align-items-center">
                                                    <b>{message.creationDateToShow}</b>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col sm={12} className="d-flex align-items-center">
                                                    <img
                                                        alt="warning"
                                                        src={message.httpLink}
                                                        className="warning-image"
                                                        onClick={() => this.openModal(index)}
                                                        style={{ cursor: 'pointer' }}
                                                    />
                                                </Col>
                                            </Row>
                                        </ListGroupItem>
                                        : null
                                : null
                        )
                    }
                </ListGroup>
            );
        } else {
            return <span>{strings.noMessagesToShow}</span>;
        }
    }

    render() {
        const { showCarousel } = this.state;

        return (
            <div className="landing-page-panel mb-5">
                <div className="landing-page-banner">
                    <div className="landing-page-ave-logo">
                        <img src="icons/ave-mobilidade.png" width={"35%"} alt="Icon" />
                    </div>
                </div>

                <Row className="landing-page-icons-row">
                    <LinkContainer to={'/network'}>
                        <Col xs={6} sm={4} lg={2} className="landing-page-icon-col">
                            <img className="landing-page-icon-img" src="icons/landing-page-network-icon.png" alt="" />
                            <span className="text-align-center align-self-center">{strings.landingPageNetwork}</span>
                        </Col>
                    </LinkContainer>
                    <LinkContainer to={'/plan-route'}>
                        <Col xs={6} sm={4} lg={2} className="landing-page-icon-col">
                            <img className="landing-page-icon-img" src="icons/landing-page-route-planning-icon.png" alt="" />
                            <span className="text-align-center align-self-center">{strings.landingPageRoutePlanning}</span>
                        </Col>
                    </LinkContainer>
                    <LinkContainer to={'/schedules'}>
                        <Col xs={6} sm={4} lg={2} className="landing-page-icon-col">
                            <img className="landing-page-icon-img" src="icons/landing-page-schedules-icon.png" alt="" />
                            <span className="text-align-center align-self-center">{strings.schedules}</span>
                        </Col>
                    </LinkContainer>
                    <LinkContainer to={'/ticket-info'}>
                        <Col xs={6} sm={4} lg={2} className="landing-page-icon-col">
                            <img className="landing-page-icon-img" src="icons/landing-page-tickets-icon.png" alt="" />
                            <span className="text-align-center align-self-center">{strings.landingPageTickets}</span>
                        </Col>
                    </LinkContainer>
                    <LinkContainer to={'/service-on-demand'}>
                        <Col xs={6} sm={4} lg={2} className="landing-page-icon-col">
                            <img className="landing-page-icon-img" src="icons/landing-page-service-on-demand-icon.png" alt="" />
                            <span className="text-align-center align-self-center">{strings.landingPageServiceOnDemand}</span>
                        </Col>
                    </LinkContainer>
                    <LinkContainer to={'/contacts'}>
                        <Col xs={6} sm={4} lg={2} className="landing-page-icon-col">
                            <img className="landing-page-icon-img" src="icons/landing-page-contact-icon.png" alt="" />
                            <span className="text-align-center align-self-center">{strings.landingPageContacts}</span>
                        </Col>
                    </LinkContainer>
                </Row>

                <div className="landing-page-warnings-panel">
                    <span className="landing-page-warnings-header">
                        <div className="icon-warning" /> &nbsp; {strings.messages}
                    </span>
                    <hr className="landing-page-warnings-divider" />
                    {this.renderMessages()}
                    <LinkContainer to={'/news'}>
                        <div className="landing-page-see-more-warnings-button white-space-no-wrap orange-text">
                            {strings.seeMore}
                        </div>
                    </LinkContainer>
                </div>

                <Carousel showCarousel={showCarousel} onClose={this.handleCarouselClose} />
            </div>
        );
    }
}
